.upload-images {
  height: 100vh;
  overflow-y: auto;

  .upload-images__navbar {
  }

  .upload-images__gallery {
    .gallery__images-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .images-container__img {
        position: relative;
        border-radius: 16px;
        width: 300px;
        height: 200px;
        background-color: #ffff;
        padding: 2px;

        [alt="delete icon"] {
          position: absolute;
          top: 24px;
          left: 24px;
          border-radius: 100%;
          width: 30px;
          background-color: #ffffff50;
          cursor: pointer;
        }

        .button-copy {
          position: absolute;
          top: 16px;
          right: 0;
          background-color: #ffffff50;
          padding: 8px 16px;
        }

        [alt="gallery item"] {
          border-radius: 16px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .upload-images__upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #button-default {
      width: 200px;
    }
  }
}

.confirm-delete-post-modal-content {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  padding: 24px;

  .confirm-delete-post-modal-content__text {
    h1 {
      margin-bottom: 24px;
      text-align: center;
    }

    p {
      color: var(--black);
    }
  }

  .confirm-delete-post-modal-content__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
