#text-area-default {
  //margin: 16px 0;

  textarea {
    border: 1px solid var(--ash);
    border-radius: 24px;
    width: 100%;
    background-color: var(--white);
    padding: 24px 16px;
    color: var(--dawn);
    font-family: "Roboto Regular";
    font-size: 0.85rem;
    //margin-top: 8px;
    min-height: 48px;
    resize: none;
    overflow-y: auto;

    &::placeholder {
      color: var(--gray);
    }

    &.is-warning {
      outline: 1px solid var(--peach);
    }
  }

  label {
    padding-bottom: 8px;
    color: var(--black);
    font-family: "Roboto Regular";
    font-size: 1rem;
  }
}
