#input-text-default {
  //margin: 16px 0;

  input {
    border: 1px solid var(--ash);
    border-radius: 24px;
    width: 100%;
    background-color: var(--white);
    padding: 12px 0 12px 16px;
    color: var(--dawn);
    font-family: "Roboto Regular";
    font-size: 14px;

    &.is-warning {
      outline: 1px solid var(--peach);
    }

    &::placeholder {
      color: var(--gray);
    }
  }

  .label-input-text {
    padding: 16px 0;
    color: var(--black);
    font-family: "Roboto Regular";
    font-size: 16px;
    line-height: 1.4;

    &.white-label {
      color: var(--white);
    }
  }
}
