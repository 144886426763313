.login-page {
  position: relative;
  height: 100%;
  display: grid;

  .images-login-container {
    height: 100vh;
  }

  .form-login-page {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 100%;
    display: grid;
    padding: 0 24px;
    display: grid;
    gap: 16px;
    max-width: 400px;
    transform: translate(-50%, -50%);

    [alt="logo Thiago Costa"] {
      width: 60%;
      align-self: center;
      justify-self: center;
    }
  }
}
