.blog-publisher {
  .theme-input-container {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr 200px;
    gap: 8px;

    &__input {
    }

    &__button {
    }

    .themes-list {
      display: flex;
      flex-wrap: wrap;
      font-family: "Roboto Regular";
      gap: 12px;

      .theme-item {
        position: relative;
        border: 1px solid var(--gray);
        border-radius: 8px;
        padding: 8px 32px 8px 8px;

        [alt="x icon"] {
          position: absolute;
          top: 0;
          right: 0;
          width: 24px;
          cursor: pointer;
        }
      }
    }
  }

  .button-publisher {
    width: 100%;
  }
}
