.ora-project-page {
    .main-info {
        margin-top: 80px;
        text-align: center;
    }

    .phrase-img {
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: block;
    }

    .horizontal-button-list {
        margin: 24px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 16px;

        .vertical-buttons {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    @media only screen and (min-width: 30em) {
        .buttons-wrapper {
            flex-direction: row;
        }

        .horizontal-button-list {
            flex-direction: row;

            .vertical-buttons {
                flex-direction: column;
            }
        }
    }

    @media only screen and (min-width: 64em) {
        .buttons-wrapper {
            #button-default {
                width: 400px;
            }
        }
    }
}
