#footer-default {
    width: 100%;
    height: 100%;

    .copy-right {
        margin-bottom: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 24px;
        color: var(--gray);
        font-family: "Roboto Regular";
        font-weight: 400;
        align-content: center;
        text-align: center;

        p {
            margin: 16px 0;
        }

        .logos-container {
            display: flex;
            align-items: center;
            //margin-bottom: 24px;

            [alt="Fortune Logo"] {
                width: auto;
                height: 30px;
            }
        }

        .social-medias {
            display: flex;
            //margin-top: 24px;
            //gap: 24px;

            a {
                width: 24px;
                height: 24px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    padding: 1px;
                }
            }
        }
    }

    @media only screen and (min-width: 45em) {
        display: grid;
        max-width: 76.5em;

        .contact-us-form-container {
            display: grid;
            gap: 24px;
            grid-template-columns: 1fr 1fr;

            .footer-default__form {
                width: 100%;
                display: flex;
                flex-direction: column;
                max-width: 600px;
                justify-self: center;
            }
        }

        .copy-right {
            .logos-container {
                display: flex;
                align-items: center;
                //margin-bottom: 24px;

                span {
                    margin: 0 12px;
                    border-left: 1px solid var(--gray);
                }

                // [alt="Fortune Logo"] {
                    //   width: auto;
                    //   height: 50px;
                    // }

                    // [alt="Christie's Logo"] {
                        //     margin-left: 8px;
                        //     height: 32px;
                        // }
                    }
                }
            }

            @media only screen and (min-width: 64em) {
                }
            }
