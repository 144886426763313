#resume {
  margin-bottom: 40px;
  width: 100%;
  // padding: 0 24px;

  .contact-info-container {
    margin: 40px 0;
    width: 100%;

    .contact-info-container__table-tbody {
      display: grid;
      gap: 4px;
    }

    h2 {
      font-family: "Raleway Regular";
      font-weight: 500;
      font-size: 40px;
    }

    h3 {
      font-family: "Raleway Regular";
      font-weight: 500;
      font-size: 24px;
    }

    .table-key {
      padding-right: 8px;
      font-weight: 400;
    }

    .table-value {
      color: var(--gray);
      font-weight: 400;
    }
  }

  .resume-texts {
    width: 100%;
    max-width: 600px;

    .sub-title {
      margin-bottom: 40px;
      padding-top: 8px;
      font-family: "Raleway Regular";
      font-weight: 500;
      font-size: 16px;
      text-align: left;
      text-transform: uppercase;
    }
  }

  img {
    border-radius: 8px;
    width: 100%;
    height: 442px;
    object-fit: cover;
    // object-position: 0 -20px;
  }
}

.copy-right {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  color: var(--gray);
  font-family: "Roboto Regular";
  font-weight: 400;
  text-align: center;

  p {
    margin: 16px 0;
  }

  .logos-container {
    display: flex;
    align-items: flex-start;
    //margin: 12px 0;
    gap: 12px;
  }

  .social-medias {
    display: flex;
    //margin-top: 24px;
    gap: 24px;

    a {
      width: 24px;
      height: 24px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 32em) {
  #resume {
    img {
      object-position: 0 -70px;
    }
  }
}

@media only screen and (min-width: 45em) {
  #resume {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
    justify-items: center;

    .contact-info-container {
      margin-top: 0;
    }

    .resume-texts {
      max-width: 600px;
    }

    img {
      height: 100%;
      max-width: 600px;
      object-position: top;
    }
  }

  .copy-right {
    justify-content: space-between;

    .logos-container {
      display: flex;
      align-items: center;
      //margin-bottom: 24px;

      span {
        margin: 0 12px;
        border-left: 1px solid var(--gray);
      }

      [alt="Fortune Logo"] {
        margin-right: 8px;
        height: 32px;
      }

      [alt="Christie's Logo"] {
        margin-left: 8px;
        height: 32px;
      }
    }
  }
}
