.list-blog-edit {
  &__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .list-blog-edit__edit-article {
    .theme-input-container {
      display: grid;
      align-items: end;
      grid-template-columns: 1fr 200px;
      gap: 8px;

      &__input {
      }

      &__button {
      }

      .themes-list {
        display: flex;
        flex-wrap: wrap;
        font-family: "Roboto Regular";
        gap: 12px;

        .theme-item {
          position: relative;
          border: 1px solid var(--gray);
          border-radius: 8px;
          padding: 8px 32px 8px 8px;

          [alt="x icon"] {
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            cursor: pointer;
          }
        }
      }
    }

    .button-publisher {
      width: 100%;
    }
  }
}

.confirm-delete-post-modal-content {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  padding: 24px;

  .confirm-delete-post-modal-content__text {
    h1 {
      margin-bottom: 24px;
      text-align: center;
    }

    p {
      color: var(--black);
    }
  }

  .confirm-delete-post-modal-content__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
