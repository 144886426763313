#nav-bar-landing-page {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  transition: background-color 0.3s ease;

  .list-your-property {
    width: 100%;
  }

  &.scrolled {
    //border-radius: 0 0 16px 16px;
    border-bottom: 1px solid var(--faded);
    background: var(--white);
  }

  .trigger {
    display: none;
  }

  .logo-container {
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 100px;
      height: auto;
    }
  }

  .button-container-nav-bar {
    display: flex;
    align-items: center;
    gap: 24px;

    #button {
      margin: auto;
      //min-width: 150px;
    }

    ul.nav {
      display: flex;
      list-style: none;
      gap: 0px;
      transition: transform 0.3s ease-in-out;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        width: 100%;
        display: block;
        padding: 12px 24px;
        color: var(--white);
        font-family: "Montserrat Variable";
        font-size: 12px;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;

        &:hover {
          color: var(--blue-dark);
        }
      }
    }

    &.open {
      ul.nav {
        transform: translateX(0);
      }
    }

    &.scrolled {
      ul.nav {
        a {
          color: var(--blue-dark);
        }
      }
    }
  }

  @media only screen and (max-width: 64em) {
    padding: 0 40px;

    &.open {
      .trigger {
        &:before,
        &:after {
          background: var(--blue-dark);
        }

        &:before {
          top: 50%;
          left: 25%;
          transform: rotate(45deg);
        }

        &:after {
          top: 50%;
          left: 25%;
          transform: rotate(-45deg);
        }
      }

      .button-container-nav-bar {
        position: absolute;
        top: 0;
        right: 0;
        width: 320px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        gap: 16px;
        border-left: 1px solid var(--faded);
        background: var(--white);
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;

        #button {
          margin: 0 auto;
          max-width: calc(100% - 48px);
        }

        ul.nav {
          a {
            color: var(--blue-dark);
          }
        }
      }

      .button-container-nav-bar ul.nav {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
      }
    }

    .trigger {
      position: relative;
      z-index: 10;
      width: 40px;
      height: 40px;
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
      &:before,
      &:after {
        position: absolute;
        width: 16px;
        height: 2px;
        content: "";
        transform: translate(-50%, -50%);
        background: var(--white);
        transition: ease 0.3s;
      }

      &:before {
        top: 16px;
        left: 50%;
      }

      &:after {
        top: 24px;
        left: 50%;
      }

      &.scrolled {
        &:before,
        &:after {
          background: var(--blue-dark);
        }
      }
    }

    .button-container-nav-bar {
      display: none;
    }
  }
}
