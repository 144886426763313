#blog-articles-container {
  display: grid;
  gap: 8px;

  [alt="article figure"] {
    border-radius: 8px;
    width: 100%;
    height: 185px;
    object-fit: cover;
    order: 2;
    cursor: pointer;
  }

  .article-details {
    margin: 16px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--gray);
    font-family: "Roboto Condensed Regular";
    order: 1;
    text-align: left;

    h2 {
      color: var(--blue-light);
      font-family: "Roboto Condensed Regular";
      font-weight: 700;
      font-size: 18px;
      transition: 0.3s ease;
      cursor: pointer;

      &:hover {
        filter: brightness(0.6);
      }
    }
  }

  @media only screen and (min-width: 48em) {
    grid-template-columns: auto 1fr;
    gap: 24px;

    [alt="article figure"] {
      width: 500px;
      height: 250px;
      order: 1;
    }

    .article-details {
      margin: 0;
      order: 2;

      h2 {
        font-size: 24px;
      }
    }
  }

  @media only screen and (width >= 80em) {
  }
}
