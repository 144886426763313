#pagination-component {
  margin: 24px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  gap: 5%;

  [alt="arrow right"] {
    transform: rotate(180deg);
  }
  [alt="arrow left"],
  [alt="arrow right"] {
    height: 16px;
    padding-right: 16px;
  }

  .number-page {
    color: var(--blue-dark);
    font-family: "Roboto Regular";
    font-size: 0.85rem;
  }

  .previous-text {
    display: flex;
    color: var(--blue-dark);
    font-family: "Roboto Regular";
    font-size: 0.85rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.5);
    }
  }

  .next-text {
    color: var(--blue-dark);
    font-family: "Roboto Regular";
    font-size: 0.85rem;
    cursor: pointer;

    &:hover {
      filter: brightness(0.5);
    }
  }

  @media only screen and (width >= 48em) {
    margin: 32px 0;
    [alt="arrow left"],
    [alt="arrow right"] {
      height: 20px;
      padding: 0 16px;
    }

    .previous-text {
      font-family: "Roboto Regular";
      font-size: 0.85rem;
    }
  }

  @media only screen and (width >= 80em) {
  }
}
