#showcase-principal {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    gap: 24px;

    .showcase-principal__carousel {
        border-radius: 16px;
    }

    .showcase-details-side {
        margin-top: 24px;
        width: 100%;
        height: auto;
        display: grid;
        gap: 24px;

        .text-wrapper__title {
            font-family: "Raleway Medium";
            font-weight: 500;
            font-size: 32px;
        }

        .text-wrapper__paragraph {
            font-family: "Roboto Regular";
            font-weight: 400;
            font-size: 18px;
        }

        .images-details-container {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(2, calc(50% - 12px));
            gap: 16px;

            .image-details img {
                border-radius: 8px;
                width: 100%;
                height: 136px;
                object-fit: cover;
            }

            .text-details {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: var(--ghost);
                padding: 16px;

                [alt="icon house gray"] {
                    display: none;
                }

                &:last-child {
                    justify-content: flex-end;
                    align-items: flex-end;
                }

                // p {
                    //     // font-family: "Raleway Regular";
                    //     // font-size: 1rem;

                    //     &:last-child {
                        //         color: var(--blue-dark);
                        //         font-family: "Roboto Condensed Regular";
                        //         font-size: 40px;
                        //     }
                        // }
                    }
                }
            }

            @media only screen and (min-width: 30em) {
                .showcase-details-side {
                    .images-details-container {
                        // gap: 24px;

                        .image-details img {
                            height: 100%;
                        }
                    }
                }
            }

            @media only screen and (min-width: 80em) {
                grid-template-rows: 789px;
                grid-template-columns: 600px 1fr;

                #carousel-default {
                    height: 802px;
                }

                .showcase-details-side {
                    margin-top: 0;
                    grid-template-rows: 1fr auto;

                    #button-default {
                        margin-top: 24px;
                    }

                    .images-details-container {
                        grid-template-rows: repeat(2, 250px);

                        .image-details img {
                            width: 250px;
                            height: 100%;
                        }

                        .text-details {
                            justify-content: center;
                            padding: 40px;

                            [alt="icon house gray"] {
                                top: 5px;
                                margin-bottom: 24px;
                                width: 26px;
                                height: 28.89px;
                                display: block;
                            }

                            p {
                                font-size: 24px;

                                &:last-child {
                                    font-size: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
