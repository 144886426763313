#button-default {
  position: relative;
  border: none;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  color: var(--white);
  font-family: "Montserrat Variable";
  font-weight: 500;
  font-size: 0.75em;
  align-content: center;
  text-transform: uppercase;
  line-height: 1.4;
  // max-height: 48px;
  //letter-spacing: 2px;
  outline: none;
  overflow: hidden;
  transition: all ease 0.3s;
  cursor: pointer;
  text-wrap: nowrap;

  [alt="arrow right"] {
    margin: 8px 12px;
    width: 8px;
  }

  // &:last-child {
  //   margin-bottom: 16px;
  // }

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    display: block;
    content: "";
    background: rgba(255, 0, 122, 0.1);
    opacity: 1;
    transition: all ease 0.3s;
  }

  &:hover {
    filter: brightness(0.9);
  }

  &.white-button {
    background-color: var(--white);
    color: var(--blue-dark);
  }

  &.primary {
    color: var(--white);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    background: linear-gradient(
      91.46deg,
      var(--blue-light) 0%,
      var(--blue-dark) 99.45%
    );
  }

  &.secondary {
    position: relative;
    background: linear-gradient(135deg, var(--blue-light), var(--blue-dark));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      position: absolute;
      z-index: 1;
      border-radius: 24px;
      padding: 1px;
      content: "";
      inset: 0;
      background: linear-gradient(135deg, var(--blue-light), var(--blue-dark));
      -webkit-mask: linear-gradient(#000 0 0) content-box,
        linear-gradient(#000 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  &.tertiary {
    background-color: var(--white);
    color: var(--charcoal);

    &:hover {
      color: var(--daw);
    }
  }

  &.quaternary {
    position: relative;
    color: var(--blue-light);
    background: var(--charcoal);
    background: none;

    &:hover {
      color: var(--white);
    }

    &:after {
      display: none;
    }
  }

  &.quintenary {
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-weight: bold;
  }

  // @media only screen and (min-width: 64em) {
  //     padding: 12px 40px;
  // }
}
