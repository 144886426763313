#aside-dashboard {
  .children {
    margin-top: 90px;
    width: 100%;
    padding: 24px;
  }

  .aside-dashboard-wide {
    display: none;
  }

  .aside-dashboard-mobile {
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    border-bottom: 1px solid rgba(35, 35, 35, 0.2);

    [alt="menu burger"] {
      transform: scaleX(-1);
    }

    .aside-dashboard-mobile__ul {
      display: grid;
      gap: 8px;

      .aside-dashboard-mobile__li {
        color: white;
        cursor: pointer;

        &.disabled {
          color: var(--gray);
          cursor: not-allowed;
        }
      }
    }

    .bm-burger-button {
      position: absolute;
      top: 28px;
    }

    .bm-menu-wrap {
      top: 0;
      width: 200px !important;
    }

    .bm-overlay {
      top: 0;
    }

    .bm-burger-button {
      right: 24px;
      left: 24px;
      width: 30px;
    }

    .bm-cross-button {
      width: 34px;
      height: 44px;
    }

    .bm-cross {
      background: var(--white);
    }

    .bm-menu {
      padding: 1em;
      background: var(--blue-dark);
    }

    .bm-item {
      display: grid;
      padding: 5px 0;
      color: var(--white);
      font-family: "Raleway Medium";
      font-size: 1.4rem;
      text-decoration: none;
      transition: color 0.2s;
      line-height: 1.5;
    }
  }

  @media only screen and (width >= 48em) {
    .children {
      margin-top: 0;
      padding-left: 350px;
    }

    .aside-dashboard-mobile {
      display: none;
    }

    .aside-dashboard-wide {
      position: fixed;
      width: 300px;
      height: 100%;
      display: grid;
      color: var(--white);
      font-family: "Raleway Medium";
      font-size: 1rem;
      grid-template-rows: 200px auto;
      text-decoration: none;
      transition: color 0.2s;
      background: var(--blue-dark);

      .aside-dashboard-wide__ul {
        .aside-dashboard-wide__li {
          color: var(--white);
          cursor: pointer;

          &.disabled {
            color: var(--gray);
            cursor: not-allowed;
          }
        }
      }

      [alt="logo Thiago Costa"] {
        justify-self: center;
        align-self: center;
      }

      li {
        padding: 12px;
        border-bottom: 1px solid rgba(35, 35, 35, 0.2);
      }
    }
  }
}

@media only screen and (width >= 80em) {
}
