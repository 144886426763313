#show-case-secondary {
    display: grid;
    gap: 16px;

    .show-case-secondary__carousel {
        border-radius: 16px;
    }

    [alt="house in carousel"] {
        object-fit: cover;
    }

    .images-details-container {
        width: 100%;
        height: 132px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        [alt="home details"] {
            border-radius: 8px;
            width: 100%;
            height: 100%;
        }
    }

    .home-details-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //margin-top: 16px;
        text-align: center;
        gap: 8px;

        h2 {
            color: var(--blue-light);
            font-family: "Roboto Condensed Regular";
            font-weight: 700;
            font-size: 32px;
        }

        h3 {
            margin-bottom: 8px;
            width: 200px;
            font-family: "Roboto Regular";
            font-weight: 400;
            font-size: 16px;
        }

        #button-default {
            width: 100%;
        }
    }

    @media only screen and (min-width: 30em) {
        .images-details-container {
            height: inherit;
        }
    }

    @media only screen and (min-width: 48em) {
        .home-details-container #button-default {
            width: 50%;
        }
    }

    @media only screen and (min-width: 64em) {
        width: calc(100% - 48px);
        height: 100%;
        grid-template-columns: 60% 18% 21%;
        gap: 24px;

        .show-case-secondary__carousel {
            height: 266px;
            min-height: inherit;
        }

        .images-details-container {
            grid-template-columns: 1fr;
            gap: 24px;

            [alt="home details"] {
                height: 121px;
                object-fit: cover;
            }
        }

        .home-details-container {
            align-items: flex-end;
            text-align: right;
            gap: 8px;

            #button-default {
                width: auto;
            }

            h2 {
                font-size: 40px;
            }

            h3 {
                font-size: 16px;
            }
        }
    }
}
