#layout-default {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-container {
        position: relative;
        margin-top: 120px;
        width: 100%;
        justify-content: center;
        padding: 16px;
    }

    @media only screen and (min-width: 30em) {
        .main-container {
            margin-top: 80px;
            padding: 0px 40px;
        }
    }

    @media only screen and (min-width: 64em) {
        .main-container {
            margin-top: 120px;
            max-width: 76.5em;
            gap: 40px;
        }
    }
}
