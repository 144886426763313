#image-uploader {
  justify-items: center;

  .input-file {
    display: none;
  }

  .image-thumbnail {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 8px;

    .thumbnail-container {
      position: relative;

      img[alt^="upload-"] {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        // max-width: 95px;
        max-height: 100px;
        object-fit: cover;
      }

      [alt="delete icon"] {
        position: absolute;
        top: 8px;
        right: 13px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: 0.3s;
        filter: brightness(0.1);

        &:hover {
          filter: brightness(0.6);
        }
      }
    }
  }

  @media only screen and (min-width: 30em) {
    .image-thumbnail {
      grid-template-columns: repeat(3, auto);
      align-self: center;

      .thumbnail-container {
        img[alt^="upload-"] {
          max-width: 200px;
          max-height: 200px;
        }
      }
    }
  }

  @media only screen and (min-width: 80em) {
    .image-thumbnail {
      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}
