#blog-articles-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .blog-articles-list__article {
    position: relative;
    border-radius: 16px;
    background-color: var(--white);
    padding: 16px;
    cursor: pointer;

    .article__title {
    }

    .article__info-article {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .info-article__date-create {
      }

      .info-article__date-update {
      }
    }
  }

  [alt="arrow right"] {
    position: absolute;
    top: 50%;
    right: 24px;
    height: 18px;
    transform: translate(-50%, -50%);
  }
}
