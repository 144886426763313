.blog-articles-page {
  .title-image-principal {
    margin: 24px 0;
    display: grid;
    //gap: 24px;

    #title-component {
      font-size: 34px;
    }

    [alt="principal"] {
      border-radius: 8px;
      width: 100%;
      max-height: 400px;
      max-height: 400px;
      object-fit: cover;
    }

    .author-post {
      font-family: "Roboto Regular";
      font-size: 1.1rem;

      span {
        font-weight: 600;
        font-size: 1rem;
      }

      .date-post {
        font-family: "Roboto Regular";
        font-size: 0.75rem;
        //margin-top: 16px;
      }
    }

    .tags-post {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .tags-post-text {
        border-radius: 16px;
        background-color: var(--ghost);
        padding: 8px 16px;
        color: var(--blue-dark);
        font-family: "Roboto Regular";
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
  }

  .blog-article {
    display: grid;
    gap: 24px;
    // grid-template-columns: 1fr 1fr;
    .image-from-blog,
    .text-from-blog {
      border-radius: 8px;
      width: 100%;
      font-family: "Roboto Regular";
      font-size: 20px;
      //min-height: 200px;
      object-fit: cover;
      line-height: 1.4;
      //text-indent: 20px;
    }
    .image-from-blog,
    .text-from-blog {
      border-radius: 8px;
      width: 100%;
      height: auto;
      font-family: "Roboto Regular";
      font-size: 16px;
      line-height: 1.4;
      //text-indent: 20px;
    }

    .text-from-blog {
      //margin: 24px 0;
    }
  }

  @media only screen and (width >= 48em) {
    #title-component {
      margin-bottom: 20px;
      font-size: 50px;
    }

    .author-post {
      font-size: 30px;

      span {
        font-weight: 600;
        font-size: 25px;
      }
    }

    .date-post {
      font-size: 16px;
    }
    .image-from-blog,
    .text-from-blog {
      border-radius: 8px;
      font-size: 20px;
      //min-height: 200px;
      line-height: 1.4;
      //text-indent: 20px;
    }

    .image-from-blog {
      max-height: 600px;
    }

    .tags-post {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .tags-post-text {
        font-size: 18px;
      }
    }
  }

  @media only screen and (width >= 80em) {
  }
}
