.toastContainer {
  position: fixed;
  z-index: 1;
  left: 50%;
  border: 1px solid var(--gray-light);
  border-radius: 16px;
  width: 342px;
  height: 94px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  //padding: 24px 0;
  transform: translateX(-50%);
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

.iconContainer {
  position: absolute;
  top: 24px;
  left: 24px;
  margin-right: 16px;
  border: 1px solid var(--gray-light);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  margin-left: 80px;
  background-color: var(--white);
}

.textPrimary {
  margin-bottom: 4px;
  background-color: var(--white);
  color: var(--black);
  font-family: "Roboto Regular";
  font-weight: 600;
  font-size: 16px;
}

.textSecondary {
  background-color: var(--white);
  color: var(--gray-dark);
  font-family: "Roboto Regular";
  font-weight: 400;
  font-size: 14px;
}

.toastContainer.show {
  z-index: 1;
  top: 24px;
  animation-name: slideIn;
}

.toastContainer.hide {
  z-index: 1;
  top: 24px;
  animation-name: slideOut;
}

@keyframes slideIn {
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@keyframes slideOut {
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, -100%);
  }
}
